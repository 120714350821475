import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      //NoBranchCode
      login_button: "Let's go!!",
      login_placeholder: "Please enter the branch code.",
      login_error_message: "Branch information not found.",
      login_yup_message: "Please enter the branch code.",

      // MaintenancePage
      maintenance_text1: "System Error Encountered",
      maintenance_text2: "We are working to resolve the issue.",
      maintenance_text3: "Sorry for your inconvenience and Thank you for your patience.",

      //Header
      header_text1: "EasyDrink - Thirsty!?, we here!",

      //MainSelect
      main_select_text1: "Product List",
      main_select_text2: "Out of stock",
      main_select_text3: "Disabled",
      main_select_text4: "Unavailable",
      main_select_text5: "Pay",
      main_select_text6: "THB",
      main_select_text7: "The system will temporarily stop service for maintenance",
      main_select_text8: "on 21 Mar 25 at 11.55 PM - 22 Mar 25 at 06.00 AM",
      main_select_text9: "and 22 Mar 25 at 11.55 PM - 23 Mar 25 at 04.00 AM",
      main_select_text10: "System will temporarily stop",
      main_select_text11: "close",


      //Payments
      payments_text1: "QTY.",
      payments_text2: "pack",
      payments_text3: "THB",
      payments_text4: "*1 slot contains",
      payments_text5: "Please select a payment method.",

      //Qrcode
      qrcode_text1: "Amount Due",
      qrcode_text2: "THB",
      qrcode_text3: "Please take a screenshot.",
      qrcode_text4: "or",
      qrcode_text5: "Press and hold the QR code to download.",
      qrcode_text6: "Download QR Code",

      //Alert
      alert_text1: "Please make sure you take 2 packs of water.",
      alert_text2: "Please remember to close the cabinet.",
      alert_text3: "If you encounter any issues or have suggestions,",
      alert_text4: "We're always here to listen.",
    },
  },
  th: {
    translation: {
      //NoBranchCode
      login_button: "ไปกันเลย!!",
      login_placeholder: "กรุณากรอกรหัสสาขา",
      login_error_message: "ไม่พบข้อมูลสาขา",
      login_yup_message: "กรุณากรอกรหัสสาขา",

      // MaintenancePage
      maintenance_text1: "ขออภัยเครื่องพบปัญหาการใช้งาน",
      maintenance_text2: "เรากำลังดำเนินการแก้ไขให้เร็วที่สุด",
      maintenance_text3: "ขออภัยในความไม่สะดวก เรากำลังแก้ไขปัญหาอย่างรวดเร็ว",

      //Header
      header_text1: "EasyDrink ยินดีให้บริการค่ะ",

      //MainSelect
      main_select_text1: "รายการสินค้า",
      main_select_text2: "สินค้าหมด",
      main_select_text3: "ปิดใช้งาน",
      main_select_text4: "ไม่พร้อมใช้งาน",
      main_select_text5: "ชำระเงิน",
      main_select_text6: "บาท",
      main_select_text7: "ระบบจะปิดปรับปรุงเพื่อพัฒนาระบบจ่ายเงินให้ดียิ่งขึ้น",
      main_select_text8: "ในวันที่ 21 มี.ค. 68 เวลา 23.55น. - 22 มี.ค. 68 เวลา 06.00น.",
      main_select_text9: "และในวันที่ 22 มี.ค. 68 เวลา 23.55น. - 22 มี.ค. 68 เวลา 04.00น.",
      main_select_text10: "ระบบจะปิดปรับปรุง",
      main_select_text11: "ปิด",



      //Payments
      payments_text1: "จำนวน",
      payments_text2: "แพ็ค",
      payments_text3: "บาท",
      payments_text4: "*1 ช่องมี จำนวน",
      payments_text5: "กรุณาเลือกช่องทางการชำระเงิน",

      //Qrcode
      qrcode_text1: "ยอดชำระ",
      qrcode_text2: "บาท",
      qrcode_text3: "กรุณา Capture หน้าจอ",
      qrcode_text4: "หรือ",
      qrcode_text5: "กดค้างที่ QR code เพื่อดาวน์โหลด",
      qrcode_text6: "ดาวน์โหลด QR Code",

      //Alert
      alert_text1: "โปรดหยิบน้ำให้ครบ 2 แพ็ค",
      alert_text2: "อย่าลืมปิดตู้ด้วยนะคะ",
      alert_text3: "หากพบปัญหาการใช้งานหรือแนะนำบริการ",
      alert_text4: "เราพร้อมรับฟังคุณเสมอ",
    },
  },
};
const browserLanguage = navigator.language || navigator.userLanguage;
const userLang = browserLanguage.split("-")[0];
// const found = navigator.languages.find((element) => element == "th");
// console.log(found?found:"en");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: userLang, // ตรงนี้เช็คภาษาจาก browser
    fallbackLng: "en", // ถ้าไม่พบภาษา จะกลับมาเป็นภาษาอังกฤษ
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
