import { useState, useEffect } from "react";
import { Box, Backdrop, CircularProgress, Typography } from "@mui/material";
import Header from "./Header/Header";
import MainSelect from "./Main/MainSelect";
import MaintenancePage from "./Main/MaintenancePage";
import NoBranchCode from "./Main/NoBranchCode";

import { api } from "../api";
import { useLocation } from "react-router-dom";

function Content() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const snValue = location?.state?.sn || queryParams.get("sn");
  const [device_info, setDevice_info] = useState({});
  const [sn, setSN] = useState(null);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      if (snValue) {
        const ret = await handleReset(snValue)
        handleSetData(ret)
      } else {
        setloading(false)
      }
    }
    fetchData()
  }, [snValue]);
  const handleReset = async (sn) => {
    setloading(true)
    return await api
      .get(
        `/device`,
        {
          params: {
            sn: sn
          }
        }
      ).finally(() => setloading(false))
  }
  const handlerecheck = async () => {
    const res = await api
      .get(
        `/device_number_log/last/${device_info?.data?.[0]?.sn}/1`,
        {
          params: {
            topic: `easydrink/device/${device_info?.data?.[0]?.sn}/out`
          }
        }
      )
    const res2 = await api
      .post(
        `/device/recheck`,
        {
          id: device_info?.data?.[0]?.id,
          number: ["1"],
        }
      )

    setTimeout(async () => {
      const res3 = await api
        .get(
          `/device_number_log/last/${device_info?.data?.[0]?.sn}/1`,
          {
            params: {
              topic: `easydrink/device/${device_info?.data?.[0]?.sn}/out`
            }
          }
        )
      if (!res3?.data?.data?.id || res?.data?.data?.id == res3?.data?.data?.id) {
        setDevice_info(pre => ([{ ...pre?.[0], active: 0 }]))
        const res3 = await api
          .put(
            `/device`,
            {
              id: device_info?.data?.[0]?.id,
              active: 0
            }
          )
      }
    }, 5000);
  }
  useEffect(() => {
    if (device_info?.data?.[0]?.active) {
      handlerecheck()
    }
  }, [device_info]);
  const handleSetData = (ret) => {
    const { data } = ret;
    setSN(data?.data?.[0]?.sn)
    setDevice_info(data)
    setloading(false)
  }
  // if (!sn) return (<></>)
  return (
    <>
      {(sn && device_info?.data?.[0]?.active && device_info?.data?.[0]?.status) ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            bgcolor: "#FFFFFF",
          }}
        >
          <Header device_info={device_info} snValue={sn} />
          <MainSelect device_info={device_info} snValue={sn} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            bgcolor: "#FFFFFF",
          }}
        >
          {!sn ? <NoBranchCode handleReset={handleReset} handleSetData={handleSetData} /> : <MaintenancePage />}

        </Box>
      )}
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress size={80} />
        <Typography position="absolute">loading...</Typography>
      </Backdrop>
    </>
  );
}

export default Content;
