import React, { useEffect } from "react";
import { Box, Button, Typography, Grid, Hidden } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { api, base_url } from "../../../api";

const Payments = () => {
  const location = useLocation();
  const { selectedCards, totalcash, snValue, device_info } = location.state || {
    selectedCards: [],
    totalcash: [],
    snValue: "",
    device_info: [],
  };
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleQrcodeClick = () => {
    navigate("/payment/qrcode", {
      state: { selectedCards, totalcash, snValue, device_info },
    });
  };
  const handleWalletClick = () => {
    const cardrate = 3.5; // เปอร์เซ็นต์ค่าธรรมเนียม 3.0%
    const totalWithFee = parseFloat(
      (totalcash + totalcash * (cardrate / (100 - cardrate))).toFixed(2)
    );
    // console.log(totalWithFee);
    navigate("/payment/wallet", {
      state: { selectedCards, totalcash: totalWithFee, snValue, device_info },
    });
  };

  const handleCardClick = () => {
    const cardrate = 3.5; // เปอร์เซ็นต์ค่าธรรมเนียม 3.5%
    const totalWithFee = parseFloat(
      (totalcash + totalcash * (cardrate / (100 - cardrate))).toFixed(2)
    );
    // const totalWithFee = totalcash + (totalcash * (cardrate / (100 - cardrate)));
    navigate("/payment/credit", {
      state: { selectedCards, totalcash: totalWithFee, snValue, device_info },
    });
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  function groupProductInfo(data) {
    return Object.values(
      data.reduce((acc, item) => {
        const productId = item.product_id;

        console.log(item)

        // ถ้าไม่มี product_id นี้ใน acc ให้เพิ่มเข้าไป
        if (!acc[productId]) {
          acc[productId] = {
            image: item.product.image,
            product_id: productId,
            name_en: item.product.name_en,
            name_th: item.product.name_th,
            unit_en: item.product.unit_en,
            unit_th: item.product.unit_th,
            price: item.price,
            qty: item.qty,
            sum_qty: 0,
          };
        }

        // รวม qty ของสินค้าที่มี product_id เดียวกัน
        acc[productId].sum_qty += item.qty;
        return acc;
      }, {})
    );
  }

  return (
    <Box
      sx={{
        margin: 0,
        textAlign: "center",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ padding: 0, marginTop: 2 }}>
        <Typography className="font-payment" style={{ lineHeight: "20px" }}>
          ({snValue})
        </Typography>
        <Typography className="font-payment-location">
          {device_info?.data[0]?.device_name}
          {/* ลุมพินี คอนโดทาวน์เอกชัย 48 ตึก b2 */}
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ padding: 2 }}
      >
        {groupProductInfo(selectedCards)?.map((e) => {
          // console.log(e);

          return (
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  padding: "12px 12px",
                  background: "#FFFFFF",
                  borderRadius: "10px",
                  boxShadow: "1px 3px 4px rgba(0, 0, 0, 0.25)",
                  // width: "100%",
                  // height: "76px",
                  // "&:hover": {
                  //   background: "#FFFFFF",
                  // },
                  // display: "flex",
                  // alignItems: "center",
                  // gap: 2,
                  // position: "relative",
                  // padding: 2, marginTop: 2
                }}
              >
                <Box sx={{ width: "100%", display: "flex", gap: 2 }}>
                  <img
                    src={`${base_url + "/download/" + e?.image}`}
                    style={{
                      width: "93px",
                      height: "92px",
                      top: "123px",
                      left: "33px",
                      gap: "0px",
                      opacity: "0px",
                    }}
                  />
                  <Box
                    sx={{
                      width: "100%",
                      display: "flow",
                      alignContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Kanit",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20.93px",
                        textAlign: "left",
                        // text-underline-position: from-font,
                        // text-decoration-skip-ink: none,
                      }}
                    >
                      {e?.[`name_${i18n.language}`]}
                    </Typography>
                    <Typography
                      sx={{
                        // position: "absolute",
                        // width: 223px,
                        // height: 22px,
                        // left: "143px",
                        // top: "176px",

                        fontFamily: "Kanit",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "24px",
                        lineHeight: "24px",
                        display: "flex",
                        alignItems: "center",
                        color: "#006EF9",
                        paddingTop: 0.4,

                        textAlign: "left",
                      }}
                    >
                      {t('payments_text1')} {e.sum_qty} {e?.[`unit_${i18n.language}`]} ({totalcash} {t('payments_text3')})
                    </Typography>
                    <Typography
                      sx={{
                        /* *1 ช่องมี จำนวน 2 แพ็ค */

                        // position: "absolute",
                        // width: 223px;
                        // height: 15px;
                        // left: 143px;
                        // top: 198px;

                        fontFamily: "Kanit",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "18px",
                        lineHeight: "18px",
                        display: "flex",
                        alignItems: "center",

                        color: "#000000",
                      }}
                    >
                      {t('payments_text4')} {e.qty} {t('payments_text2')}
                    </Typography>
                  </Box>
                </Box>
                {/* <Grid container  >
                <Grid item sx={3} >
                  
                </Grid>
                <Grid item sx={9} >
                  

                </Grid>
              </Grid> */}
                {/* <Typography className="font-payment">
                กรุณาเลือกช่องทางการชำระเงิน
              </Typography> */}
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Box sx={{ padding: 2, marginTop: 2 }}>
        <Typography className="font-payment">
          {t('payments_text5')}
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ padding: 2 }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              padding: "12px 24px",
              background: "#ffffff",
              borderRadius: "10px",
              boxShadow: "0px 3px 4px 0px #00000040",
              width: "100%",
              height: "76px",
              "&:hover": {
                background: "#FFFFFF",
              },
            }}
            onClick={handleQrcodeClick}
          >
            <Box className="border-prompay">
              <img
                src="/logos/prompay.png"
                alt="Prompay"
                className="img-prompay"
              />
            </Box>
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              padding: "12px 24px",
              background: "#ffffff",
              borderRadius: "10px",
              boxShadow: "0px 3px 4px 0px #00000040",
              width: "100%",
              height: "76px",
              "&:hover": {
                background: "#FFFFFF",
              },
              display: "flex",
              alignItems: "center",
              gap: 2,
              position: "relative",
            }}
            onClick={handleCardClick}
          >
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "50px",
                height: "50px",
                background: "#F44335",
                clipPath: "polygon(0 100%, 0 0, 100% 100%)",
                borderBottomLeftRadius: "10px",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 10,
                left: -3,
                zIndex: 4,
                transform: "rotate(45deg)",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontWeight: 800,
                  fontSize: "15px",
                  color: "#FFFFFF",
                }}
              >
                + 3.5%
              </Typography>
            </Box>

            {/* Images */}
            <img src="/logos/visa.png" alt="Visa" className="img-visa" />
            <img
              src="/logos/mastercard.png"
              alt="Mastercard"
              className="img-mastercard"
            />
            <img src="/logos/jcb.png" alt="JCB" className="img-jcb" />
            <img
              src="/logos/unionpay.png"
              alt="UnionPay"
              className="img-union"
            />
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              padding: "12px 24px",
              background: "#ffffff",
              borderRadius: "10px",
              boxShadow: "0px 3px 4px 0px #00000040",
              width: "100%",
              height: "76px",
              "&:hover": {
                background: "#FFFFFF",
              },
              display: "flex",
              alignItems: "center",
              gap: 2,
              position: "relative",
            }}
            onClick={handleWalletClick}
          >
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "50px",
                height: "50px",
                background: "#F44335",
                clipPath: "polygon(0 100%, 0 0, 100% 100%)",
                borderBottomLeftRadius: "10px",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 10,
                left: -3,
                zIndex: 4,
                transform: "rotate(45deg)",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontWeight: 800,
                  fontSize: "15px",
                  color: "#FFFFFF",
                }}
              >
                + 3.5%
              </Typography>
            </Box>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Grid item sx={2.4}>
                <img
                  src="/logos/image 15.png"
                  alt="truewallet"
                  style={{
                    width: "41px",
                    height: "31px",
                    top: "524px",
                    left: "49px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                />
              </Grid>
              <Grid item sx={2.4}>
                <img
                  src="/logos/image 16.png"
                  alt="rabbit"
                  style={{
                    width: "40px",
                    height: "31px",
                    top: "524px",
                    left: "104px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                />
              </Grid>
              <Grid item sx={2.4}>
                <img
                  src="/logos/image 17.png"
                  alt="shopee"
                  style={{
                    width: "50px",
                    height: "24px",
                    top: "528px",
                    left: "159px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                />
              </Grid>
              <Grid item sx={2.4}>
                <img
                  src="/logos/image 18.png"
                  alt="alipay"
                  style={{
                    width: "56px",
                    height: "16px",
                    top: "532px",
                    left: "229px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                />
              </Grid>
              <Grid item sx={2.4}>
                <img
                  src="/logos/image 19.png"
                  alt="wechat"
                  style={{
                    width: "65px",
                    height: "20px",
                    top: "530px",
                    left: "308px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                />
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>

      <Box
        sx={{
          textAlign: "center",
          backgroundImage: "url('/logos/Wave1.png')",
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "328px",
          marginTop: "auto",
        }}
      />
    </Box>
  );
};

export default Payments;
