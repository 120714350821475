import React, { useState, useEffect } from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { api } from "../../../api";
import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from 'react-i18next';

const Alert = () => {
  const location = useLocation();
  const { selectedCards, snValue, device_info } = location.state || {
    selectedCards: [],
    snValue: "",
    device_info: [],
  };
  const { t, i18n } = useTranslation();
  const [status, setStatus] = useState(null); // เก็บสถานะ response

  console.log(device_info?.data?.[0].id);

  useEffect(() => {
    let interval;
    if (selectedCards) {
      interval = setInterval(async () => {
        const response = await getRecheck(selectedCards);
        const responsecheck = await getStatus(selectedCards);
      }, 15000);
    }
    return () => clearInterval(interval);
  }, [selectedCards]);

  const getRecheck = async (data) => {
    const response = await api.post(
      `/device/recheck`,
      {
        // id: snValue,
        id: device_info?.data?.[0].id,
        number: selectedCards.map((item) => String(item.device_number)),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const getStatus = async (data) => {
    let arr = [];
    let all202 = true;
    for await (const [i, e] of data.entries()) {
      await api
        .get(`/device_number_log/last/${snValue}/${e.device_number}`, {})
        .then(async (ret) => {
          const { data } = ret;
          arr.push(data);
          if (ret !== 202) {
            all202 = false;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }
    if (all202) {
      setStatus(202);
    } else {
      setStatus("not_202");
    }
    return arr;
  };

  useEffect(() => { }, [status]);

  console.log(status);

  return (
    <Box
      sx={{
        margin: 0,
        textAlign: "center",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {status === null && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              flexWrap: "wrap",
              padding: 2,
              marginTop: 2,
            }}
          >
            <img
              src="/logos/Warning.png"
              alt="Banner"
              className="w-full h-auto"
              style={{ width: 303, height: 136 }}
            />
          </Box>
          <Typography
            sx={{
              left: "30px",
              fontFamily: "Kanit",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "29px",
              lineHeight: "43px",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              color: "#1151D1",
              paddingX: "30px",
            }}
          >
            {/* โปรดตรวจสอบสินค้าในช่องทั้งหมดก่อนปิดประตู */}
            {t('main_select_text1')}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              flexWrap: "wrap",
              padding: 2,
            }}
          >
            <img
              src="/logos/Laststep.png"
              alt="Banner"
              className="w-full h-auto"
              style={{ width: 356, height: 333 }}
            />
          </Box>
        </>
      )}

      {status === "not_202" && (
        <>
          <Box
            sx={{
              flexWrap: "wrap",
              padding: 2,
              marginTop: 4,
            }}
          >
            <Player
              autoplay
              loop
              src="/animation/alert1.json"
              style={{ height: "416px", width: "372px" }}
            />
            <Box
              sx={{
                marginTop: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px !important",
                  fontFamily: "Kanit, sans-serif !important",
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                {t('main_select_text2')}
              </Typography>
            </Box>
          </Box>
        </>
      )}

      <Box
        sx={{
          marginTop: "auto",
          textAlign: "center",
          backgroundImage: "url('/logos/Wave3.png')",
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          margin: 0,
          padding: 0,
          // width: "430px",
          // height: "286px",
        }}
      >
        <Box sx={{ marginTop: 12 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              padding: "12px 24px",
              background: "#39cd00",
              borderRadius: "11px",
              width: "199px",
              height: "56px",
              "&:hover": {
                background: "#39cd00",
              },
              fontSize: "22px",
              fontWeight: "bold",
              fontFamily: "Kanit, sans-serif",
              fontWeight: 500,
              color: "#FFFFFF",
              textTransform: "none",
            }}
          >
            <img
              src="/logos/line.png"
              alt="Banner"
              className="plr-2"
              style={{ width: 38, height: 35 }}
            />
            <Typography
              sx={{
                fontSize: "23px !important",
                fontWeight: 500,
                color: "#FFFFFF",
              }}
            >
              @
            </Typography>
            <Typography
              sx={{
                fontSize: "20px !important",
                fontFamily: "Kanit, sans-serif !important",
                fontWeight: 500,
                color: "#FFFFFF",
              }}
            >
              easydrink.th
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 2,
            display: "flex",
            placeContent: "center",
          }}
        >
          <Card
            sx={{
              background: "#FFFFFF",
              borderRadius: "11px",
              border: "3px solid  #006EF9",
              width: "325px",
              height: "81px",
              alignContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "Kanit, sans-serif",
                fontWeight: 500,
                color: "#000000",
                textTransform: "none",
              }}
            >
              {t('main_select_text3')}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "Kanit, sans-serif",
                fontWeight: 500,
                color: "#000000",
                textTransform: "none",
              }}
            >
              {t('main_select_text4')}
            </Typography>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default Alert;
