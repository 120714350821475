import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { base_url, api } from "../../api";
import * as qs from "qs";
import { useTranslation } from 'react-i18next';
import moment from "moment/moment";

const testimages = [
  {
    original: "/logos/1.png",
    thumbnail: "/logos/1.png",
  },
  {
    original: "/logos/2.png",
    thumbnail: "/logos/2.png",
  },
  {
    original: "/logos/3.png",
    thumbnail: "/logos/3.png",
  },
];

const Header = ({ device_info, snValue }) => {
  const [headerimages, setHeaderimages] = useState([]);
  const { t, i18n } = useTranslation();
  const Headerimages =
    device_info?.data?.[0].device_images?.map((item) => ({
      original: `${base_url + "/download/" + item?.image}`,
      thumbnail: `${base_url + "/download/" + item?.image}`,
    })) || [];
  console.log(Headerimages);
  useEffect(() => {
    handlereGetdata(device_info)
  }, [device_info]);
  const handlereGetdata = async (dara) => {
    console.log(dara);
    const res = await api
      .get(`/advertisements_devices_associate`, {
        params: {
          device_id: device_info?.data?.[0]?.id,
          sort: "asc",
          order_by: "ads_id",
        },
      })
    const resdata = await res?.data?.data || []

    const resadvertisements = await api
      .get(`/advertisements`, {
        params: {
          id: resdata?.map(e => e.ads_id),
          "filterrange[ads_start_date]": `-|${moment()?.startOf("D")?.format("YYYY-MM-DD  HH:mm:ss")}`,
          "filterrange[ads_end_date]": `${moment()?.endOf("D")?.format("YYYY-MM-DD  HH:mm:ss")}|-`,
          sort: "asc",
          order_by: "piority",
        },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      })
    const resdadvertisements = await resadvertisements?.data?.data || []

    setHeaderimages(resdadvertisements?.map((item) => ({
      original: `${base_url + "/download/" + item?.ads_image_id?.[0]?.image}`,
      thumbnail: `${base_url + "/download/" + item?.ads_image_id?.[0]?.image}`,
    })))

    for (const [index, data] of resdadvertisements?.entries()) {
      const response1 = await api
        .post(
          `/advertisements_view`,
          {
            "ads_id": data.id,
            "view": 1,
            "view_date": new Date().toISOString()
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
    }

  };

  // useEffect(() => {
  //   handlereGetdata(device_info)
  // }, [device_info]);

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        textAlign: "center",
        md: { textAlign: "left" },
      }}
    >
      <Box className="flex-1">
        <Typography
          className="font-header pl-128"
          sx={{
            textAlign: "left",
            marginTop: 2,
            paddingLeft: 4,
            paddingRight: 4,
          }}
        >
          {t('header_text1')}
        </Typography>
        <Box
          className="flex pl-128"
          sx={{ paddingLeft: 3.5, paddingRight: 4, marginBottom: 2 }}
        >
          <LocationOnIcon sx={{ fontSize: 24 }} />
          <Typography
            className="font-subheader"
            sx={{
              textAlign: "left",
            }}
          >
            {device_info?.data?.[0]?.device_name}
          </Typography>
        </Box>
      </Box>
      <Box className="flex-1 custom-gallery">
        <ImageGallery
          items={
            headerimages?.length > 0
              ? headerimages
              : testimages
          }
          // items={Headerimages}
          showThumbnails={false}
          showNav={false}
          autoPlay={true}
          showPlayButton={false}
          showBullets={true}
          showFullscreenButton={false}
          // infinite={true}
          slideInterval={3000}
        />
      </Box>
    </Box>
  );
};

export default Header;
