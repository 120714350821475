import axios from "axios";
const base_url = `${process.env.REACT_APP_API_URL}`
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/easydrink/`,
  headers: {
    "X-EASYDRINK-API-Key": `${process.env.REACT_APP_EASYDRINK_APIKEY}`
  }
});

export { api, base_url };
