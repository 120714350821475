import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useTranslation } from 'react-i18next';

const Maintenance = ({ open, onClose }) => {
  const { t, i18n } = useTranslation();
  return (
    // <Modal
    //   open={open}
    //   onClose={(event, reason) => {
    //     if (reason !== "backdropClick") {
    //       onClose();
    //     }
    //   }}
    //   disableEnforceFocus
    //   disableAutoFocus
    // >
    //   <Box className="m-maintenance">
    //     <Typography className="font-maintenance">ระบบจะปิดปรับปรุง</Typography>
    //     <div style={{ paddingTop: 16 }}>
    //       <Typography className="font-maintenance-sub">
    //         วันที่ 23 มกราคม 2568
    //       </Typography>
    //       <Typography className="font-maintenance-sub">
    //         ระหว่างเวลา 10:00 น. - 12:00 น.
    //       </Typography>
    //       <Typography className="font-maintenance-sub">
    //         ขออภัยในความไม่สะดวกค่ะ
    //       </Typography>
    //     </div>

    //     <Button
    //       onClick={onClose}
    //       sx={{
    //         padding: "12px 24px",
    //         background: "#0074EC",
    //         borderRadius: "11px",
    //         width: "159px",
    //         height: "36px",
    //         "&:hover": {
    //           background: "#0074EC",
    //         },
    //         fontSize: "22px",
    //         fontWeight: "bold",
    //         fontFamily: "Kanit, sans-serif",
    //         fontWeight: 500,
    //         color: "#FFFFFF",
    //         textTransform: "none",
    //         mt: 2,
    //       }}
    //     >
    //       ปิด
    //     </Button>
    //   </Box>
    // </Modal>
    // <Modal
    //   open={open}
    //   onClose={(event, reason) => {
    //     if (reason !== "backdropClick") {
    //       onClose();
    //     }
    //   }}
    //   disableEnforceFocus
    //   disableAutoFocus
    // >
    //   <Box className="m-maintenance">
    //     <Typography className="font-maintenance">ระบบจะปิดปรับปรุง</Typography>
    //     <Box className="content-m">
    //       <Typography className="font-maintenance-sub">
    //         วันที่ 23 มกราคม 2568
    //       </Typography>
    //       <Typography className="font-maintenance-sub">
    //         ระหว่างเวลา 10:00 น. - 12:00 น.
    //       </Typography>
    //       <Typography className="font-maintenance-sub">
    //         ขออภัยในความไม่สะดวกค่ะ
    //       </Typography>
    //     </Box>

    //     <Button onClick={onClose} className="maintenance-btn">
    //       ปิด
    //     </Button>
    //   </Box>
    // </Modal>
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box className="m-maintenance">
        <Typography className="font-maintenance">{t('main_select_text10')}</Typography>

        <Box className="content-m">
          <Typography className="font-maintenance-sub">
            {t('main_select_text7')}
          </Typography>
          <Typography className="font-maintenance-sub">
            {t('main_select_text8')}
          </Typography>
          <br></br>
          <Typography className="font-maintenance-sub">
            {t('main_select_text9')}
          </Typography>
          <br></br>
        </Box>

        <Button onClick={onClose} className="maintenance-btn ">
          ปิด
        </Button>
      </Box>
    </Modal>
  );
};

export default Maintenance;
